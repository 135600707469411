import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { removeAllFromCart } from '../actions/cartActions';
import { Container, Nav, Navbar, NavDropdown, Badge } from 'react-bootstrap';
import { logout, userCurrencyConvert } from '../actions/userActions';
import SearchBox from './SearchBox';

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userCurrency = useSelector((state) => state.userCurrency);
	const { conversionRates } = userCurrency;

	const cart = useSelector((state) => state.cart);
	const { cartItems } = cart;

	// Calculate the total quantity of items in the cart
	const cartItemCount = cartItems.reduce((acc, item) => acc + item.qty, 0);

	///console.log(Number(rates));

	function isEmpty(obj) {
		for (var x in obj) {
			if (obj.hasOwnProperty(x)) return false;
		}
		return true;
	}
	// console.log(
	// 	new Date().toLocaleDateString('en-CA') + ' ' + conversionRates.date
	// );
	useEffect(() => {
		if (isEmpty(conversionRates) || Date.now() !== conversionRates.date) {
			dispatch(userCurrencyConvert());
		}
		// eslint-disable-next-line
	}, []);

	const logoutHandler = () => {
		try {
			dispatch(logout());
			// NOTE: here we need to reset cart state for when a user logs out so the next
			// user doesn't inherit the previous users cart and shipping
			dispatch(removeAllFromCart());
			navigate('/login');
		} catch (err) {
			console.error(err);
		}
	};
	return (
		<header>
			<Navbar
				bg='light'
				variant='light'
				expand='lg'
				collapseOnSelect
				className='border-0 p-0'
			>
				<Container>
					<LinkContainer to='/'>
						<Navbar.Brand>
							<img src='/images/logo-header.png' alt='Sow Trendy! Logo' />
						</Navbar.Brand>
					</LinkContainer>

					<Navbar.Toggle aria-controls='basic-navbar-nav' />

					<Navbar.Collapse
						id='basic-navbar-nav'
						className='justify-content-between'
					>
						<Routes>
							<Route path='/' element={<SearchBox navigate={navigate} />} />
							<Route path='/*' element={<SearchBox navigate={navigate} />} />
						</Routes>

						<Nav className='ml-auto'>
							<LinkContainer to='/cart'>
								<Nav.Link className='cart-icon'>
									<i className='fas fa-shopping-cart'></i>
									<span className='cart-text'>Cart</span>
									{cartItemCount > 0 && (
										<Badge pill bg='danger' className='cart-badge'>
											{cartItemCount}
										</Badge>
									)}
								</Nav.Link>
							</LinkContainer>
							{userInfo ? (
								<NavDropdown title={userInfo.name} id='username'>
									<LinkContainer to='/profile'>
										<NavDropdown.Item>Profile</NavDropdown.Item>
									</LinkContainer>
									{userInfo &&
										userInfo.role &&
										(userInfo.role === 'Admin' ||
											userInfo.role === 'Individual-seller' ||
											userInfo.role === 'Company-seller') && (
											<>
												<LinkContainer to='/seller/productlist'>
													<NavDropdown.Item>Products</NavDropdown.Item>
												</LinkContainer>
												<LinkContainer to='/seller/orderlist'>
													<NavDropdown.Item>Orders</NavDropdown.Item>
												</LinkContainer>
											</>
										)}

									<NavDropdown.Item onClick={logoutHandler}>
										Logout
									</NavDropdown.Item>
								</NavDropdown>
							) : (
								<LinkContainer to='/login'>
									<Nav.Link>
										<i className='fas fa-user'></i>Sign In
									</Nav.Link>
								</LinkContainer>
							)}

							{userInfo && userInfo.role && (
								<NavDropdown
									title={userInfo.role === 'Admin' ? 'Admin' : 'Distributor'}
									id='adminmenu'
								>
									{userInfo.role === 'Admin' && (
										<>
											<LinkContainer to='/admin/userlist'>
												<NavDropdown.Item>Users</NavDropdown.Item>
											</LinkContainer>
											<LinkContainer to='/admin/productlist'>
												<NavDropdown.Item>Products</NavDropdown.Item>
											</LinkContainer>
										</>
									)}
									<LinkContainer to='/admin/orderlist'>
										<NavDropdown.Item>Orders</NavDropdown.Item>
									</LinkContainer>
								</NavDropdown>
							)}
							<LinkContainer to='/help'>
								<Nav.Link>
									<i className='fas fa-help'></i>Help
								</Nav.Link>
							</LinkContainer>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	);
};

export default Header;
