// src/components/ProductForm.js

import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import ProductDiscount from './ProductDiscount';
import Loader from './Loader';

const ProductForm = ({
	name,
	setName,
	price,
	setPrice,
	currency,
	setCurrency,
	image,
	setImage,
	brand,
	setBrand,
	countInStock,
	setCountInStock,
	description,
	setDescription,
	showDiscount,
	setShowDiscount,
	isDiscountActive,
	setIsDiscountActive,
	discountValue,
	setDiscountValue,
	discountUnit,
	setDiscountUnit,
	validUntil,
	setValidUntil,
	couponCode,
	setCouponCode,
	uploading,
	uploadFileHandler,
	mainCategory,
	mainCategorySelected,
	setMainCategorySelected,
	subCategory,
	subCategorySelected,
	setSubCategorySelected,
	thirdCategory,
	thirdCategorySelected,
	setThirdCategorySelected,
	fourthCategory,
	fourthCategorySelected,
	setFourthCategorySelected,
	fifthCategory,
	fifthCategorySelected,
	setFifthCategorySelected,
	sizes,
	setSizes,
	clothes_sizes,
	shoes_sizes,
	handleDiscountChange,
	submitHandler,
	getCategoryId,
	isEdit,
	product_created_at,
	product_updated_at,
	previousSelectedCategory,
	previousSelectedSubcategory,
	previousSelectedThirdcategory,
	previousSelectedFourthcategory,
	previousSelectedFifthcategory,
	previousSizesSelected,
	firstCategorySelectedHandler,
	secondCategorySelectedHandler,
	thirdCategorySelectedHandler,
	fourthCategorySelectedHandler,
	fifthCategorySelectedHandler,
	sizesSelectedHandler,
	currencies,
	loadingDiscount,
	errorDiscount,
	productId,
}) => {
	return (
		<Form onSubmit={submitHandler} className='product-create-form'>
			<Row>
				<Col md={6} sm={12} className='mb-4 custom-col'>
					<Form.Group controlId='name'>
						<Form.Label>
							Name <span style={{ color: 'red' }}>*</span>
						</Form.Label>
						<Form.Control
							type='name'
							placeholder='Enter name'
							value={name}
							onChange={(e) => setName(e.target.value)}
							required
							disabled={isEdit && product_created_at !== product_updated_at}
						></Form.Control>
					</Form.Group>
				</Col>

				<Col md={6} sm={12} className='mb-4 custom-col'>
					<Form.Group controlId='price'>
						<Form.Label>
							Price <span style={{ color: 'red' }}>*</span>
						</Form.Label>
						<Form.Control
							type='number'
							placeholder='Enter price'
							value={price}
							onChange={(e) => setPrice(e.target.value)}
							required
						></Form.Control>
					</Form.Group>
				</Col>

				<Col md={6} sm={12} className='mb-4 custom-col'>
					<Form.Group controlId='currency'>
						<Form.Label>
							Choose Currency <span style={{ color: 'red' }}>*</span>
						</Form.Label>
						<Form.Control
							as='select'
							value={currency}
							onChange={(e) => setCurrency(e.target.value)}
							required
							disabled={isEdit && product_created_at !== product_updated_at}
						>
							{Object.keys(currencies)
								.filter((item) => item === 'INR')
								.map((item) => (
									<option
										value={currencies[item].code}
										key={currencies[item].code}
									>
										{currencies[item].code} - {currencies[item].symbol}
									</option>
								))}
						</Form.Control>
					</Form.Group>
				</Col>

				<Col md={6} sm={12} className='mb-4 custom-col'>
					<Form.Group controlId='image'>
						<Form.Label>
							Image(s) <span style={{ color: 'red' }}>*</span>
						</Form.Label>
						<Form.Control
							type='text'
							placeholder='Enter image URL'
							value={image}
							hidden={true}
							onChange={(e) => setImage(e.target.value)}
							required={!isEdit}
						></Form.Control>
						<Form.Group controlId='multi-files'>
							<Form.Control
								type='file'
								accept='image/*'
								multiple
								onChange={uploadFileHandler}
								required={!isEdit}
								disabled={isEdit && product_created_at !== product_updated_at}
							/>
						</Form.Group>
						{uploading && <Loader />}
					</Form.Group>
				</Col>

				<Col md={6} sm={12} className='mb-4 custom-col'>
					<Form.Group controlId='brand'>
						<Form.Label>
							Brand <span style={{ color: 'red' }}>*</span>
						</Form.Label>
						<Form.Control
							type='text'
							placeholder='Enter brand'
							value={brand}
							onChange={(e) => setBrand(e.target.value)}
							required
							disabled={isEdit && product_created_at !== product_updated_at}
						></Form.Control>
					</Form.Group>
				</Col>

				<Col md={6} sm={12} className='mb-4 custom-col'>
					<Form.Group controlId='countInStock'>
						<Form.Label>
							Count In Stock <span style={{ color: 'red' }}>*</span>
						</Form.Label>
						<Form.Control
							type='number'
							placeholder='Enter Count In Stock'
							value={countInStock}
							onChange={(e) => {
								const value = Math.max(0, e.target.value);
								setCountInStock(value);
							}}
							required
						></Form.Control>
					</Form.Group>
				</Col>

				<Col md={6} sm={12} className='mb-4 custom-col'>
					<Form.Group controlId='categories'>
						<Form.Label>
							Choose Category & Sub Categories{' '}
							<span style={{ color: 'red' }}>*</span>
						</Form.Label>

						<Typeahead
							id='first-category'
							clearButton
							labelKey='category'
							onChange={firstCategorySelectedHandler}
							options={mainCategory}
							placeholder='Choose a category...'
							selected={
								mainCategorySelected.length !== 0
									? mainCategorySelected
									: previousSelectedCategory
							}
							disabled={isEdit && product_created_at !== product_updated_at}
							required
						/>
						{(subCategory[0] != null ||
							previousSelectedSubcategory.length !== 0) && (
							<Typeahead
								style={{ marginTop: '20px' }}
								id='sub-category'
								clearButton
								labelKey='subCategory'
								onChange={secondCategorySelectedHandler}
								options={subCategory}
								placeholder='Choose a sub category...'
								selected={
									subCategorySelected.length !== 0
										? subCategorySelected
										: previousSelectedSubcategory
								}
								disabled={isEdit && product_created_at !== product_updated_at}
								required
							/>
						)}

						{(thirdCategory[0] != null ||
							previousSelectedThirdcategory.length !== 0) && (
							<Typeahead
								style={{ marginTop: '20px' }}
								id='third-category'
								clearButton
								labelKey='levelThreeCategory'
								onChange={thirdCategorySelectedHandler}
								options={thirdCategory}
								placeholder='Choose a sub category...'
								selected={
									thirdCategorySelected.length !== 0
										? thirdCategorySelected
										: previousSelectedThirdcategory
								}
								disabled={isEdit && product_created_at !== product_updated_at}
								required
							/>
						)}

						{(fourthCategory[0] != null ||
							previousSelectedFourthcategory.length !== 0) && (
							<Typeahead
								style={{ marginTop: '20px' }}
								id='fourth-category'
								clearButton
								labelKey='levelFourCategory'
								onChange={fourthCategorySelectedHandler}
								options={fourthCategory}
								placeholder='Choose a sub category...'
								selected={
									fourthCategorySelected.length !== 0
										? fourthCategorySelected
										: previousSelectedFourthcategory
								}
								disabled={isEdit && product_created_at !== product_updated_at}
								required
							/>
						)}

						{(fifthCategory[0] != null ||
							previousSelectedFifthcategory.length !== 0) && (
							<Typeahead
								style={{ marginTop: '20px' }}
								id='fifth-category'
								clearButton
								labelKey='levelFiveCategory'
								onChange={fifthCategorySelectedHandler}
								options={fifthCategory}
								placeholder='Choose a sub category...'
								selected={
									fifthCategorySelected.length !== 0
										? fifthCategorySelected
										: previousSelectedFifthcategory
								}
								disabled={isEdit && product_created_at !== product_updated_at}
								required
							/>
						)}
					</Form.Group>
				</Col>

				<Col md={6} sm={12} className='mb-4 custom-col'>
					<Form.Group controlId='sizes'>
						<Form.Label>
							Size(s) <span style={{ color: 'red' }}>*</span>
						</Form.Label>
						<Typeahead
							id='product-size'
							multiple
							clearButton
							labelKey='sizes'
							onChange={sizesSelectedHandler}
							options={
								mainCategorySelected.length !== 0 &&
								(mainCategorySelected.includes('Shoes') ||
									mainCategorySelected.includes('Clothes'))
									? mainCategorySelected.includes('Shoes')
										? shoes_sizes
										: clothes_sizes
									: ['N/A']
							}
							placeholder='Choose available size(s)...'
							selected={sizes.length !== 0 ? sizes : previousSizesSelected}
							required
						/>
					</Form.Group>
				</Col>

				<Col md={6} sm={12} className='mb-4 custom-col'>
					<Form.Group controlId='description'>
						<Form.Label>
							Description <span style={{ color: 'red' }}>*</span>
						</Form.Label>
						<Form.Control
							as='textarea'
							rows={3}
							placeholder='Enter Description'
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							required
						></Form.Control>
					</Form.Group>
				</Col>
			</Row>

			<Form.Group controlId='showDiscount'>
				<Form.Check
					type='checkbox'
					label='Add Discount'
					checked={showDiscount}
					onChange={(e) => handleDiscountChange(e.target.checked)}
				></Form.Check>
			</Form.Group>
			{isDiscountActive && (
				<div>
					<p>Discount is currently active!</p>
					{/* Additional UI elements related to the active discount */}
				</div>
			)}

			{showDiscount && (
				<ProductDiscount
					discountValue={discountValue}
					setDiscountValue={setDiscountValue}
					discountUnit={discountUnit}
					setDiscountUnit={setDiscountUnit}
					validUntil={validUntil}
					setValidUntil={setValidUntil}
					couponCode={couponCode}
					setCouponCode={setCouponCode}
					loadingDiscount={loadingDiscount}
					errorDiscount={errorDiscount}
					productId={productId}
				/>
			)}

			<Button
				type='submit'
				variant='primary'
				onMouseEnter={
					isEdit
						? product_created_at === product_updated_at
							? getCategoryId
							: null
						: getCategoryId
				}
			>
				{isEdit ? 'Update' : 'Create'}
			</Button>
		</Form>
	);
};

export default ProductForm;
