import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { listProductDetails, updateProduct } from '../actions/productActions';
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants';
import { currencies } from '../components/Currencies';
import { getAllCategoriesByCategoryId } from '../actions/categoryActions';
import { createorupdateProductDiscount } from '../actions/discountActions';
import ProductForm from '../components/ProductForm';

const ProductEditScreen = () => {
	const { id: productId } = useParams();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const isEditMode = true;

	const [name, setName] = useState('');
	const [price, setPrice] = useState(0);
	const [currency, setCurrency] = useState('INR');
	const [image, setImage] = useState('');
	const [brand, setBrand] = useState('');
	const [countInStock, setCountInStock] = useState(0);
	const [description, setDescription] = useState('');
	const [showDiscount, setShowDiscount] = useState(false);

	const [isDiscountActive, setIsDiscountActive] = useState(false);

	const [discountValue, setDiscountValue] = useState(0);
	const [discountUnit, setDiscountUnit] = useState('percentage');
	const [validUntil, setValidUntil] = useState('');
	const [couponCode, setCouponCode] = useState('');

	const [uploading, setUploading] = useState(false);
	const [mainCategory, setMainCategory] = useState([]);
	const [mainCategorySelected, setMainCategorySelected] = useState([]);
	const [subCategory, setSubCategory] = useState([]);
	const [subCategorySelected, setSubCategorySelected] = useState([]);
	const [thirdCategory, setThirdCategory] = useState([]);
	const [thirdCategorySelected, setThirdCategorySelected] = useState([]);
	const [fourthCategory, setFourthCategory] = useState([]);
	const [fourthCategorySelected, setFourthCategorySelected] = useState([]);
	const [fifthCategory, setFifthCategory] = useState([]);
	const [fifthCategorySelected, setFifthCategorySelected] = useState([]);
	const [categoryId, setCategoryId] = useState(0);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [previousSelectedCategory, setPreviousSelectedCategory] = useState([]);
	const [previousSelectedSubcategory, setPreviousSelectedSubcategory] =
		useState([]);
	const [previousSelectedThirdcategory, setPreviousSelectedThirdcategory] =
		useState([]);
	const [previousSelectedFourthcategory, setPreviousSelectedFourthcategory] =
		useState([]);
	const [previousSelectedFifthcategory, setPreviousSelectedFifthcategory] =
		useState([]);
	const [isClear, setIsClear] = useState(0);
	const [sizes, setSizes] = useState([]);
	const [previousSizesSelected, setPreviousSizesSelected] = useState([]);
	const clothes_sizes = [
		'S',
		'M',
		'L',
		'XL',
		'XXL',
		'3XL',
		'4XL',
		'5XL',
		'6XL',
		'7XL',
	];
	const shoes_sizes = ['6', '7', '8', '9', '10'];

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const productDetails = useSelector((state) => state.productDetails);
	const { loading, error, product } = productDetails;
	const product_created_at = product ? product.createdAt : null;
	const product_updated_at = product ? product.updatedAt : null;

	const discountList = useSelector((state) => state.productDiscountsList);
	const { discounts } = discountList;

	const productUpdate = useSelector((state) => state.productUpdate);
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = productUpdate;

	const { loading: loadingDiscount, error: errorDiscount } = useSelector(
		(state) => state.productDiscountDetails
	);

	const getFirstLevelCategoryData = async (e) => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		const { data } = await axios.get('/api/category/firstlevel', config);
		setMainCategory(data);
	};
	if (mainCategory.length === 0) getFirstLevelCategoryData();

	const uploadFileHandler = async (e) => {
		const files = e.target.files;
		const formData = new FormData();
		formData.append('userId', userInfo._id);

		//console.log(files);
		//console.log(...files);
		[...files].forEach((file) => {
			formData.append('image', file);
		});

		setUploading(true);

		try {
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};
			const { data } = await axios.post('/api/upload', formData, config);
			setImage(data);
			setUploading(false);
		} catch (error) {
			console.error(error);
			setUploading(false);
		}
	};

	const firstCategorySelectedHandler = async (first_Category_selected) => {
		if (first_Category_selected.length !== 0) {
			setMainCategorySelected(first_Category_selected);

			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userInfo.token}`,
					},
				};
				const { data } = await axios.get(
					`/api/category/secondlevel/${first_Category_selected}`,
					config
				);
				if (data && data.length > 0) {
					// Check if data is valid
					setSubCategory(data);
				} else {
					setSubCategory([]); // Set to empty array if no valid data
				}
			} catch (error) {
				console.error(error);
			}
		} else {
			setMainCategorySelected([]);
			setPreviousSelectedCategory([]);
			setPreviousSelectedSubcategory([]);
			setPreviousSelectedThirdcategory([]);
			setPreviousSelectedFourthcategory([]);
			setPreviousSelectedFifthcategory([]);
			setSizes([]);
			setIsClear(1);
		}
	};

	const secondCategorySelectedHandler = async (second_Category_selected) => {
		if (second_Category_selected.length !== 0) {
			setSubCategorySelected(second_Category_selected);
			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userInfo.token}`,
					},
				};
				const { data } = await axios.get(
					`/api/category/thirdlevel/${second_Category_selected}/${mainCategorySelected}`,
					config
				);
				if (data && data.length > 0) {
					// Check if data is valid
					setThirdCategory(data);
				} else {
					setThirdCategory([]); // Set to empty array if no valid data
				}
			} catch (error) {
				console.error(error);
			}
		} else {
			setSubCategorySelected([]);
			setPreviousSelectedSubcategory([]);
			setIsClear(1);
		}
	};

	const thirdCategorySelectedHandler = async (third_Category_selected) => {
		if (third_Category_selected.length !== 0) {
			setThirdCategorySelected(third_Category_selected);
			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userInfo.token}`,
					},
				};
				const { data } = await axios.get(
					`/api/category/fourthlevel/${third_Category_selected}/${subCategorySelected}`,
					config
				);
				if (data && data.length > 0) {
					// Check if data is valid
					setFourthCategory(data);
				} else {
					setFourthCategory([]); // Set to empty array if no valid data
				}
			} catch (error) {
				console.error(error);
			}
		} else {
			setThirdCategorySelected([]);
			setPreviousSelectedThirdcategory([]);
			setIsClear(1);
		}
	};

	const fourthCategorySelectedHandler = async (fourth_Category_selected) => {
		if (fourth_Category_selected.length !== 0) {
			setFourthCategorySelected(fourth_Category_selected);
			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userInfo.token}`,
					},
				};
				const { data } = await axios.get(
					`/api/category/fifthlevel/${fourth_Category_selected}/${thirdCategorySelected}`,
					config
				);
				if (data && data.length > 0) {
					// Check if data is valid
					setFifthCategory(data);
				} else {
					setFifthCategory([]); // Set to empty array if no valid data
				}
			} catch (error) {
				console.error(error);
			}
		} else {
			setFourthCategorySelected([]);
			setPreviousSelectedFourthcategory([]);
			setIsClear(1);
		}
	};

	const fifthCategorySelectedHandler = async (fifth_Category_selected) => {
		if (fifth_Category_selected.length !== 0) {
			setFifthCategorySelected(fifth_Category_selected);
		} else {
			setFifthCategorySelected([]);
			setPreviousSelectedFifthcategory([]);
			setIsClear(1);
		}
	};

	const sizesSelectedHandler = async (user_sizes_selected) => {
		if (user_sizes_selected.length !== 0) {
			setSizes(user_sizes_selected);
		} else {
			setSizes([]);
			setPreviousSizesSelected([]);
			setIsClear(1);
		}
	};

	const getCategoryId = async (e) => {
		const all_categories = {};
		try {
			if (mainCategorySelected) {
				//console.log(mainCategorySelected);
				all_categories['mainCategory'] = mainCategorySelected[0];
			}
			if (subCategorySelected) {
				all_categories['subCategory'] = subCategorySelected[0];
			}
			if (thirdCategorySelected) {
				all_categories['thirdCategory'] = thirdCategorySelected[0];
			}
			if (fourthCategorySelected) {
				all_categories['fourthCategory'] = fourthCategorySelected[0];
			}
			if (fifthCategorySelected) {
				all_categories['fifthCategory'] = fifthCategorySelected[0];
			}
			//console.log(all_categories);
			const { data } = await axios.get(`/api/category/getcategoryid/`, {
				params: {
					query: JSON.stringify(all_categories),
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userInfo.token}`,
				},
			});
			//console.log(data);
			setCategoryId(data);
		} catch (error) {
			console.error(error);
		}
	};
	useEffect(() => {
		const fetchCategories = async () => {
			if (
				selectedCategories.length === 0 &&
				product.name &&
				product.categoryId
			) {
				const data = await getAllCategoriesByCategoryId(product.categoryId);
				setSelectedCategories(data);
			}
		};

		fetchCategories();
	}, [product, selectedCategories]);

	useEffect(() => {
		if (discounts && discounts.length > 0) {
			const productDiscount = discounts.find(
				(discount) => discount.product_id === productId
			);
			console.log(productDiscount);
			if (productDiscount) {
				setDiscountValue(productDiscount.discount_value);
				setDiscountUnit(productDiscount.discount_unit);
				setValidUntil(productDiscount.valid_until.split('T')[0]);
				setCouponCode(productDiscount.coupon_code);
				setShowDiscount(productDiscount.activate);
				setIsDiscountActive(productDiscount.activate);
			}
		}
	}, [discounts, productId]);

	const handleDiscountChange = (checked) => {
		setShowDiscount(checked);
		setIsDiscountActive(checked);
	};

	const isValidDiscountDate = (date) => {
		const today = new Date();
		const tomorrow = new Date();
		tomorrow.setDate(today.getDate() + 1); // Set to tomorrow

		return new Date(date) >= tomorrow; // Check if the date is at least tomorrow
	};

	useEffect(() => {
		const fetchCategories = async () => {
			if (
				selectedCategories.length === 0 &&
				product.name &&
				product.categoryId
			) {
				const data = await getAllCategoriesByCategoryId(product.categoryId);

				setSelectedCategories(data);
			}
		};

		fetchCategories();
	}, [product, selectedCategories]);

	const validateCategories = () => {
		if (isEditMode) {
			// Skip validation if in edit mode
			return true;
		}
		if (mainCategorySelected.length === 0) {
			return false;
		}
		if (
			Array.isArray(subCategory) &&
			subCategory.some((item) => item !== undefined && item !== null) &&
			subCategorySelected.length === 0
		) {
			return false;
		}
		if (
			Array.isArray(thirdCategory) &&
			thirdCategory.some((item) => item !== undefined && item !== null) &&
			thirdCategorySelected.length === 0
		) {
			return false;
		}
		if (
			Array.isArray(fourthCategory) &&
			fourthCategory.some((item) => item !== undefined && item !== null) &&
			fourthCategorySelected.length === 0
		) {
			return false;
		}
		if (
			Array.isArray(fifthCategory) &&
			fifthCategory.some((item) => item !== undefined && item !== null) &&
			fifthCategorySelected.length === 0
		) {
			return false;
		}
		return true;
	};

	const submitHandler = (e) => {
		e.preventDefault();
		if (validateCategories(isEditMode)) {
			// Check if validUntil is set and validate it
			if (showDiscount && validUntil && !isValidDiscountDate(validUntil)) {
				alert('The discount date must be at least one day in advance.');
				return; // Prevent form submission
			}
			//const categoryId = parseInt(finalCategoryId);
			//console.log(categoryId);
			// adding 10% flat on all the products.
			// TODO: after creating category ID's, create rules of margin based on product category
			//const final_price = parseInt(price) + parseInt(0.25 * price);

			if (product_created_at === product_updated_at) {
				dispatch(
					updateProduct({
						_id: productId,
						name,
						price,
						currency,
						brand,
						description,
						countInStock,
						image,
						categoryId,
						sizes,
					})
				);
			} else {
				dispatch(
					updateProduct({
						_id: productId,
						//name,
						price,
						//currency,
						//brand,
						description,
						countInStock,
						//image,
						//categoryId,
						sizes,
					})
				);
			}

			if (showDiscount || !showDiscount) {
				dispatch(
					createorupdateProductDiscount({
						product_id: productId,
						discount_value: discountValue,
						discount_unit: discountUnit,
						valid_until: validUntil,
						coupon_code: couponCode,
						activate: showDiscount,
					})
				);
			}
		} else {
			alert('Please fill all category and subcategory levels.');
		}
	};

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: PRODUCT_UPDATE_RESET });
			navigate('/admin/productlist');
		} else {
			//console.log(product.name + ' ' + product._id + ' ' + productId);
			if (!product.name || product._id !== productId) {
				//console.log('inside product details');
				dispatch(listProductDetails(productId));
			} else {
				if (!isClear) {
					//alert('in useeffect');
					//setMainCategory(all_categories.category);
					setName(product.name);
					setPrice(product.price);
					setCurrency(product.currency);
					setImage(product.image);
					setBrand(product.brand);
					setCountInStock(product.countInStock);
					setSizes(product.sizes);
					setDescription(product.description);
					if (selectedCategories[0] && product.categoryId !== 0) {
						if (
							selectedCategories[0].category &&
							previousSelectedCategory.length === 0
						) {
							setPreviousSelectedCategory([selectedCategories[0].category]);
						}
						if (
							selectedCategories[0].subCategory &&
							previousSelectedSubcategory.length === 0
						)
							setPreviousSelectedSubcategory([
								selectedCategories[0].subCategory,
							]);
						if (
							selectedCategories[0].levelThreeCategory &&
							previousSelectedThirdcategory.length === 0
						)
							setPreviousSelectedThirdcategory([
								selectedCategories[0].levelThreeCategory,
							]);
						if (
							selectedCategories[0].levelFourCategory &&
							previousSelectedFourthcategory.length === 0
						)
							setPreviousSelectedFourthcategory([
								selectedCategories[0].levelFourCategory,
							]);
						if (
							selectedCategories[0].levelFiveCategory &&
							previousSelectedFifthcategory.length === 0
						)
							setPreviousSelectedFifthcategory([
								selectedCategories[0].levelFiveCategory,
							]);
					}
				}
			}
		}
	}, [
		dispatch,
		navigate,
		productId,
		product,
		successUpdate,
		userInfo,
		mainCategory,
		selectedCategories,
		previousSelectedCategory,
		previousSelectedSubcategory,
		previousSelectedThirdcategory,
		previousSelectedFourthcategory,
		previousSelectedFifthcategory,
		isClear,
	]);

	return (
		<>
			<Link to='/admin/productlist' className='btn btn-light my-3'>
				Go Back
			</Link>
			<FormContainer>
				<h1>Edit Product</h1>
				{loadingUpdate && <Loader />}
				{errorUpdate && <Message variant='danger'> {errorUpdate}</Message>}
				{loading ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : (
					<ProductForm
						name={name}
						setName={setName}
						price={price}
						setPrice={setPrice}
						currency={currency}
						setCurrency={setCurrency}
						image={image}
						setImage={setImage}
						brand={brand}
						setBrand={setBrand}
						countInStock={countInStock}
						setCountInStock={setCountInStock}
						description={description}
						setDescription={setDescription}
						showDiscount={showDiscount}
						setShowDiscount={setShowDiscount}
						isDiscountActive={isDiscountActive}
						setIsDiscountActive={setIsDiscountActive}
						discountValue={discountValue}
						setDiscountValue={setDiscountValue}
						discountUnit={discountUnit}
						setDiscountUnit={setDiscountUnit}
						validUntil={validUntil}
						setValidUntil={setValidUntil}
						couponCode={couponCode}
						setCouponCode={setCouponCode}
						uploading={uploading}
						uploadFileHandler={uploadFileHandler}
						mainCategory={mainCategory}
						mainCategorySelected={mainCategorySelected}
						setMainCategorySelected={setMainCategorySelected}
						subCategory={subCategory}
						subCategorySelected={subCategorySelected}
						setSubCategorySelected={setSubCategorySelected}
						thirdCategory={thirdCategory}
						thirdCategorySelected={thirdCategorySelected}
						setThirdCategorySelected={setThirdCategorySelected}
						fourthCategory={fourthCategory}
						fourthCategorySelected={fourthCategorySelected}
						setFourthCategorySelected={setFourthCategorySelected}
						fifthCategory={fifthCategory}
						fifthCategorySelected={fifthCategorySelected}
						setFifthCategorySelected={setFifthCategorySelected}
						sizes={sizes}
						setSizes={setSizes}
						clothes_sizes={clothes_sizes}
						shoes_sizes={shoes_sizes}
						handleDiscountChange={handleDiscountChange}
						submitHandler={submitHandler}
						getCategoryId={getCategoryId}
						isEdit={true}
						product_created_at={product.createdAt}
						product_updated_at={product.updatedAt}
						previousSelectedCategory={previousSelectedCategory}
						previousSelectedSubcategory={previousSelectedSubcategory}
						previousSelectedThirdcategory={previousSelectedThirdcategory}
						previousSelectedFourthcategory={previousSelectedFourthcategory}
						previousSelectedFifthcategory={previousSelectedFifthcategory}
						previousSizesSelected={previousSizesSelected}
						firstCategorySelectedHandler={firstCategorySelectedHandler}
						secondCategorySelectedHandler={secondCategorySelectedHandler}
						thirdCategorySelectedHandler={thirdCategorySelectedHandler}
						fourthCategorySelectedHandler={fourthCategorySelectedHandler}
						fifthCategorySelectedHandler={fifthCategorySelectedHandler}
						sizesSelectedHandler={sizesSelectedHandler}
						currencies={currencies}
						loadingDiscount={loadingDiscount}
						errorDiscount={errorDiscount}
						productId={productId}
					/>
				)}
			</FormContainer>
		</>
	);
};

export default ProductEditScreen;
