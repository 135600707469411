// OrderScreen.js
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { PayPalButton } from 'react-paypal-button-v2';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import RenderRazorpay from '../components/RenderRazorpay';
import {
	getOrderDetails,
	payOrder,
	payOrderCOD,
	deliverOrder,
} from '../actions/orderActions';
import { removeAllFromCart } from '../actions/cartActions';
import Loader from '../components/Loader';
import {
	ORDER_PAY_RESET,
	ORDER_DELIVER_RESET,
	ORDER_DETAILS_RESET,
} from '../constants/orderConstants';

import { NumericFormat } from 'react-number-format';
import { currencies } from '../components/Currencies';
import { calculateDiscountedPrice } from '../Helpers/calculateDiscountedPrice';

const OrderScreen = () => {
	const { id: orderId } = useParams();

	const [sdkReady, setSdkReady] = useState(false);
	const [displayRazorpay, setDisplayRazorpay] = useState(false);
	const [orderDetailsRazor, setOrderDetailsRazor] = useState({
		orderIdRazor: null,
		currencyRazor: null,
		amountRazor: null,
	});
	const [razorPayConfig, setRazorPayConfig] = useState({
		razorpay_key_id: null,
		razorpay_key_secret: null,
	});
	const [discountError, setDiscountError] = useState(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const orderDetails = useSelector((state) => state.orderDetails);
	const { order, loading, error } = orderDetails;

	const orderPay = useSelector((state) => state.orderPay);
	const { loading: loadingPay, success: successPay } = orderPay;

	const orderDeliver = useSelector((state) => state.orderDeliver);
	const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const cart = useSelector((state) => state.cart);
	const { paymentMethod } = cart;

	const userCurrency = useSelector((state) => state.userCurrency);
	const { conversionRates } = userCurrency;
	const user_currency = conversionRates ? Object.keys(conversionRates)[1] : '';

	const discounts = useSelector(
		(state) => state.productDiscountsList.discounts
	);

	const [discountedPrices, setDiscountedPrices] = useState({});

	// Define addDecimals function outside of any conditional
	const addDecimals = (num) => {
		return (Math.round(num * 100) / 100).toFixed(2);
	};

	// Calculate discounted prices for order items
	useEffect(() => {
		const fetchDiscountedPrices = async () => {
			if (order && order.orderItems) {
				try {
					const prices = {};
					order.orderItems.forEach((item) => {
						const discountedPrice = calculateDiscountedPrice(
							item.product,
							item.price,
							discounts
						);
						prices[item.product] =
							discountedPrice > 0 ? discountedPrice : item.price;
					});
					setDiscountedPrices(prices);
				} catch (err) {
					setDiscountError('Failed to calculate discounted prices');
				}
			}
		};

		if (order && order.orderItems) {
			fetchDiscountedPrices();
		}
	}, [order, discounts]);

	useEffect(() => {
		if (!userInfo) {
			navigate('/login');
		}
		const addPayPalScript = async () => {
			const { data: clientId } = await axios.get('/api/config/paypal');
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${user_currency.toUpperCase()}`;
			script.async = true;
			script.onload = () => {
				setSdkReady(true);
			};
			document.body.appendChild(script);
		};

		const getRazorpayConfig = async () => {
			try {
				const {
					data: { razorpay_key_id, razorpay_key_secret },
				} = await axios.get('/api/config/razorpay');
				setRazorPayConfig({
					razorpay_key_id,
					razorpay_key_secret,
				});
			} catch (error) {
				console.error('Error fetching Razorpay config:', error);
			}
		};

		if (!order || successPay || successDeliver || order._id !== orderId) {
			dispatch({ type: ORDER_PAY_RESET });
			dispatch({ type: ORDER_DELIVER_RESET });
			dispatch({ type: ORDER_DETAILS_RESET });
			dispatch(getOrderDetails(orderId));
		} else if (order && !order.isPaid && paymentMethod === 'PayPal') {
			// Added check for order
			if (!window.paypal) {
				addPayPalScript();
			} else {
				setSdkReady(true);
			}
		} else if (order && !order.isPaid && paymentMethod === 'CashOnDelivery') {
			// Added check for order
			dispatch(payOrder(orderId, paymentMethod, ''));
			dispatch(removeAllFromCart());
		} else if (order && !order.isPaid && paymentMethod === 'RazorPay') {
			// Added check for order
			getRazorpayConfig();
		}
	}, [
		dispatch,
		orderId,
		successPay,
		order,
		successDeliver,
		navigate,
		userInfo,
		paymentMethod,
		user_currency,
	]);

	const successPaymentHandler = (paymentResult) => {
		dispatch(payOrder(orderId, paymentMethod, paymentResult));
		dispatch(removeAllFromCart());
	};

	const handleRazorPayment = async (amount, currency) => {
		try {
			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			};
			const { data } = await axios.post(
				'/api/orders/RazorPay',
				{
					amount: parseInt(amount * 100), // Convert to smallest currency unit
					currency,
				},
				config
			);

			if (data && data.razor_order_id) {
				setOrderDetailsRazor({
					orderIdRazor: data.razor_order_id,
					currencyRazor: data.currency,
					amountRazor: data.amount,
				});
				setDisplayRazorpay(true);
			}
		} catch (error) {
			console.error('RazorPay Error:', error);
		}
	};

	const deliverHandler = () => {
		dispatch(deliverOrder(order));
	};
	const paidHandler = () => {
		dispatch(payOrderCOD(orderId));
	};

	// Calculate Prices using useMemo hooks unconditionally
	const originalItemsPrice = useMemo(() => {
		if (!order || !order.orderItems) return '0.00';
		return addDecimals(
			order.orderItems.reduce((acc, item) => acc + item.qty * item.price, 0)
		);
	}, [order]);

	const itemsPrice = useMemo(() => {
		if (!order || !order.orderItems) return '0.00';
		return addDecimals(
			order.orderItems.reduce((acc, item) => {
				const price = discountedPrices[item.product] || item.price;
				const convertedPrice =
					user_currency && item.currency.toLowerCase() !== user_currency
						? price /
						  (conversionRates[user_currency]?.[item.currency.toLowerCase()] ||
								1)
						: price;
				return acc + item.qty * convertedPrice;
			}, 0)
		);
	}, [order, discountedPrices, user_currency, conversionRates]);

	// Calculate total prices
	const shippingPrice = order ? addDecimals(order.shippingPrice) : '0.00';
	const taxPrice = order ? addDecimals(order.taxPrice) : '0.00';
	const totalPrice = order
		? (Number(itemsPrice) + Number(shippingPrice) + Number(taxPrice)).toFixed(2)
		: '0.00';

	// Optionally, you can avoid mutating the order object
	// and use the calculated values directly in rendering

	return loading ? (
		<Loader />
	) : error ? (
		<Message variant='danger'> {error}</Message>
	) : (
		<>
			<h1> Order {order._id} </h1>
			<Row>
				<Col md={8}>
					<ListGroup variant='flush'>
						<ListGroup.Item>
							<h2>Shipping</h2>
							<p>
								<strong>Name: </strong> {order.user.name}
							</p>
							<p>
								<a href={`mailto:${order.user.email}`}>{order.user.email}</a>
							</p>
							<p>
								<strong> Address: </strong>
								{order.shippingAddress.address}, {order.shippingAddress.city},{' '}
								{order.shippingAddress.postalCode},{' '}
								{order.shippingAddress.country}
							</p>
							{order.isDelivered ? (
								<Message variant='success'>
									Delivered on {order.deliveredAt.substring(0, 10)}
								</Message>
							) : (
								<Message variant='danger'>Not Delivered</Message>
							)}
						</ListGroup.Item>

						<ListGroup.Item>
							<h2>Payment Method: </h2>
							<p>
								<strong>{order.paymentMethod}</strong>
							</p>
							{order.isPaid ? (
								<Message variant='success'>
									Paid on {order.paidAt.substring(0, 10)}
								</Message>
							) : (
								<Message variant='danger'>Not Paid</Message>
							)}
						</ListGroup.Item>

						<ListGroup.Item>
							<h2>Order Items: </h2>
							{order.orderItems.length === 0 ? (
								<Message> Order is empty </Message>
							) : (
								<ListGroup variant='flush'>
									{order.orderItems.map((item, index) => {
										const discountedPrice =
											discountedPrices[item.product] || item.price;

										return (
											<ListGroup.Item key={index}>
												<Row>
													<Col md={1}>
														<Image
															src={item.image[0]}
															alt={item.name}
															fluid
															rounded
														></Image>
													</Col>
													<Col>
														<Link to={`/product/${item.product}`}>
															{item.name}
														</Link>
													</Col>
													<Col md={4}>
														{item.qty} x{' '}
														{user_currency &&
														item.currency &&
														item.currency.toLowerCase() !== user_currency ? (
															<>
																{item.price !== discountedPrice &&
																	discountedPrice < item.price && (
																		<>
																			<NumericFormat
																				value={(
																					item.price /
																					conversionRates[user_currency][
																						item.currency.toLowerCase()
																					]
																				).toFixed(2)}
																				displayType={'text'}
																				thousandSeparator={true}
																				prefix={
																					currencies[
																						user_currency.toUpperCase()
																					].symbol
																				}
																				className='text-muted text-decoration-line-through'
																			/>
																			&nbsp;{' '}
																		</>
																	)}
																<NumericFormat
																	value={Number(discountedPrice).toFixed(2)}
																	displayType={'text'}
																	thousandSeparator={true}
																	prefix={
																		currencies[user_currency.toUpperCase()]
																			.symbol
																	}
																	className={
																		item.price !== discountedPrice
																			? 'text-danger'
																			: ''
																	}
																/>
															</>
														) : (
															<>
																{item.price !== discountedPrice &&
																	discountedPrice < item.price && (
																		<>
																			<NumericFormat
																				value={item.price.toFixed(2)}
																				displayType={'text'}
																				thousandSeparator={true}
																				prefix={
																					user_currency &&
																					currencies[
																						user_currency.toUpperCase()
																					].symbol
																				}
																				className='text-muted text-decoration-line-through'
																			/>
																			&nbsp;{' '}
																		</>
																	)}
																<NumericFormat
																	value={Number(discountedPrice).toFixed(2)}
																	displayType={'text'}
																	thousandSeparator={true}
																	prefix={
																		user_currency &&
																		currencies[user_currency.toUpperCase()]
																			.symbol
																	}
																	className={
																		item.price !== discountedPrice
																			? 'text-danger'
																			: ''
																	}
																/>
															</>
														)}
													</Col>
												</Row>
											</ListGroup.Item>
										);
									})}
								</ListGroup>
							)}
						</ListGroup.Item>
					</ListGroup>
				</Col>
				<Col md={4}>
					<Card>
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<h2>Order Summary</h2>
							</ListGroup.Item>

							<ListGroup.Item>
								<Row>
									<Col>Items</Col>
									<Col>
										{originalItemsPrice !== itemsPrice && (
											<>
												<NumericFormat
													value={originalItemsPrice}
													displayType={'text'}
													thousandSeparator={true}
													prefix={
														user_currency
															? currencies[user_currency.toUpperCase()].symbol
															: ''
													}
													className='text-muted text-decoration-line-through'
												/>
												&nbsp;{' '}
												<NumericFormat
													value={itemsPrice}
													displayType={'text'}
													thousandSeparator={true}
													prefix={
														user_currency
															? currencies[user_currency.toUpperCase()].symbol
															: ''
													}
													className='text-danger'
												/>
											</>
										)}
										{originalItemsPrice === itemsPrice && (
											<NumericFormat
												value={itemsPrice}
												displayType={'text'}
												thousandSeparator={true}
												prefix={
													user_currency
														? currencies[user_currency.toUpperCase()].symbol
														: ''
												}
											/>
										)}
									</Col>
								</Row>
							</ListGroup.Item>

							<ListGroup.Item>
								<Row>
									<Col>Shipping</Col>
									<Col>
										<NumericFormat
											value={shippingPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[user_currency.toUpperCase()].symbol
													: ''
											}
										/>
									</Col>
								</Row>
							</ListGroup.Item>

							<ListGroup.Item>
								<Row>
									<Col>Tax</Col>
									<Col>
										<NumericFormat
											value={taxPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[user_currency.toUpperCase()].symbol
													: ''
											}
										/>
									</Col>
								</Row>
							</ListGroup.Item>

							<ListGroup.Item>
								<Row>
									<Col>Total</Col>
									<Col>
										<NumericFormat
											value={totalPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[user_currency.toUpperCase()].symbol
													: ''
											}
										/>
									</Col>
								</Row>
							</ListGroup.Item>
							{!order.isPaid && (
								<ListGroup.Item>
									{loadingPay && <Loader />}
									{!sdkReady && order.paymentMethod === 'PayPal' ? (
										<Loader />
									) : paymentMethod === 'PayPal' ? (
										<PayPalButton
											amount={totalPrice}
											currency={user_currency.toUpperCase()}
											onSuccess={successPaymentHandler}
										/>
									) : order.paymentMethod === 'RazorPay' ? (
										<div>
											<Button
												onClick={() =>
													handleRazorPayment(
														totalPrice,
														user_currency
															? currencies[user_currency.toUpperCase()].code
															: ''
													)
												}
											>
												Buy Now
											</Button>
											{displayRazorpay && (
												<RenderRazorpay
													orderId={orderId}
													orderIdRazor={orderDetailsRazor.orderIdRazor}
													keyId={razorPayConfig.razorpay_key_id}
													keySecret={razorPayConfig.razorpay_key_secret}
													currency={
														user_currency
															? currencies[user_currency.toUpperCase()].code
															: ''
													}
													amount={orderDetailsRazor.amountRazor}
													userInfo={userInfo}
												/>
											)}
										</div>
									) : (
										<ListGroup.Item>Thank you for your order!</ListGroup.Item>
									)}
								</ListGroup.Item>
							)}
							{loadingDeliver && <Loader />}
							{userInfo && userInfo.isAdmin && !order.isDelivered && (
								<ListGroup.Item>
									<Button
										type='button'
										className='btn btn-block'
										onClick={deliverHandler}
									>
										Mark As Delivered
									</Button>
								</ListGroup.Item>
							)}

							{userInfo && userInfo.isAdmin && !order.isPaid && (
								<ListGroup.Item>
									<Button
										type='button'
										className='btn btn-block'
										onClick={paidHandler}
									>
										Mark As Paid
									</Button>
								</ListGroup.Item>
							)}
							<ListGroup.Item>
								{discountError && (
									<Message variant='danger'>{discountError}</Message>
								)}
							</ListGroup.Item>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default OrderScreen;
