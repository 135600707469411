// CartScreen.js
import React, { useEffect, useCallback, useMemo } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	Row,
	Col,
	ListGroup,
	Image,
	Form,
	Button,
	Card,
} from 'react-bootstrap';
import Message from '../components/Message';
import { addToCart, removeFromCart } from '../actions/cartActions';
import { NumericFormat } from 'react-number-format';
import { currencies } from '../components/Currencies';
import { calculateDiscountedPrice } from '../Helpers/calculateDiscountedPrice'; // Import the helper function

const CartScreen = () => {
	const { id: productId } = useParams(); // Use useParams to get the productId
	const location = useLocation(); // Use useLocation to get the location object
	const qty = location.search
		? Number(new URLSearchParams(location.search).get('qty'))
		: 1;
	const dispatch = useDispatch();
	const navigate = useNavigate(); // Define navigate

	const cart = useSelector((state) => state.cart);
	const { cartItems = [] } = cart; // Ensure cartItems is initialized

	const userCurrency = useSelector((state) => state.userCurrency);
	const { conversionRates } = userCurrency;
	const user_currency = conversionRates ? Object.keys(conversionRates)[1] : ''; // No changes here

	// Access discounts from the global state
	const discounts = useSelector(
		(state) => state.productDiscountsList.discounts
	);

	// Calculate discounted prices for cart items using helper function
	const discountedPrices = useMemo(() => {
		return cartItems.reduce((acc, item) => {
			const discountedPrice = calculateDiscountedPrice(
				item.product,
				item.price,
				discounts
			);
			acc[item.product] = discountedPrice > 0 ? discountedPrice : item.price;
			return acc;
		}, {});
	}, [cartItems, discounts]);

	// Calculate total discount across all items
	const totalDiscount = useMemo(() => {
		return cartItems
			.reduce((acc, item) => {
				const originalPrice = item.price;
				const discountedPrice = discountedPrices[item.product] || item.price;
				const discountPerItem = originalPrice - discountedPrice;

				let discountInUserCurrency = 0;
				if (
					user_currency &&
					item.currency &&
					item.currency.toLowerCase() !== user_currency
				) {
					const conversionRate =
						conversionRates[user_currency] &&
						conversionRates[user_currency][item.currency.toLowerCase()];
					if (conversionRate) {
						discountInUserCurrency = discountPerItem / conversionRate;
					}
				} else {
					discountInUserCurrency = discountPerItem;
				}

				return (
					acc +
					(discountInUserCurrency > 0 ? discountInUserCurrency * item.qty : 0)
				);
			}, 0)
			.toFixed(2);
	}, [cartItems, discountedPrices, user_currency, conversionRates]);

	// Calculate total price after discounts
	const totalPrice = useMemo(() => {
		return cartItems
			.reduce(
				(acc, item) =>
					acc +
					item.qty *
						(user_currency &&
						item.currency &&
						item.currency.toLowerCase() !== user_currency
							? (
									(discountedPrices[item.product] || item.price) /
									(conversionRates[user_currency]?.[
										item.currency.toLowerCase()
									] || 1)
							  ).toFixed(2)
							: (discountedPrices[item.product] || item.price).toFixed(2)),
				0
			)
			.toFixed(2);
	}, [cartItems, conversionRates, user_currency, discountedPrices]);

	useEffect(() => {
		if (productId) {
			dispatch(addToCart(productId, qty));
		}
	}, [dispatch, productId, qty]);

	const removeFromCartHandler = useCallback(
		(id) => {
			dispatch(removeFromCart(id));
		},
		[dispatch]
	);

	const checkoutHandler = useCallback(() => {
		navigate('/login?redirect=shipping');
	}, [navigate]);

	const subtotal = useMemo(() => {
		return cartItems.reduce((acc, item) => acc + item.qty, 0);
	}, [cartItems]);

	// Calculate the original total price before discounts
	const originalTotalPrice = useMemo(() => {
		return cartItems
			.reduce(
				(acc, item) =>
					acc +
					item.qty *
						(user_currency &&
						item.currency &&
						item.currency.toLowerCase() !== user_currency
							? (
									item.price /
									(conversionRates[user_currency]?.[
										item.currency.toLowerCase()
									] || 1)
							  ).toFixed(2)
							: item.price.toFixed(2)),
				0
			)
			.toFixed(2);
	}, [cartItems, conversionRates, user_currency]);

	// Calculate the percentage discount
	const discountPercentage = useMemo(() => {
		const originalPrice = parseFloat(originalTotalPrice);
		const discountedPrice = parseFloat(totalPrice);
		const percentage =
			((originalPrice - discountedPrice) / originalPrice) * 100;
		return percentage % 1 === 0 ? percentage : Math.ceil(percentage);
	}, [originalTotalPrice, totalPrice]);

	return (
		<Row>
			<Col md={8}>
				<h1>Shopping Cart</h1>
				{cartItems.length === 0 ? (
					<Message>
						Your Cart is empty <Link to='/'>Go Back</Link>
					</Message>
				) : (
					<ListGroup variant='flush'>
						{cartItems.map((item) => {
							const discountedPrice =
								discountedPrices[item.product] || item.price;

							return (
								<ListGroup.Item key={item.product}>
									<Row>
										<Col md={2}>
											<Image
												src={item.image[0]}
												alt={item.name}
												fluid
												rounded
											/>
										</Col>
										<Col md={3}>
											<Link to={`/product/${item.product}`}>{item.name}</Link>
										</Col>
										<Col md={2}>
											{user_currency &&
											item.currency &&
											item.currency.toLowerCase() !== user_currency ? (
												<>
													{item.price !== discountedPrice &&
													discountedPrice < item.price ? (
														<>
															<NumericFormat
																value={(
																	item.price /
																	(conversionRates[user_currency]?.[
																		item.currency.toLowerCase()
																	] || 1)
																).toFixed(2)}
																displayType={'text'}
																thousandSeparator={true}
																prefix={
																	currencies[user_currency.toUpperCase()]
																		?.symbol || '$'
																}
																className='text-muted text-decoration-line-through'
															/>
															&nbsp; {/* Adding non-breaking space here */}
															<NumericFormat
																value={(
																	discountedPrice /
																	(conversionRates[user_currency]?.[
																		item.currency.toLowerCase()
																	] || 1)
																).toFixed(2)}
																displayType={'text'}
																thousandSeparator={true}
																prefix={
																	currencies[user_currency.toUpperCase()]
																		?.symbol || '$'
																}
																className='text-danger'
															/>
														</>
													) : (
														<NumericFormat
															value={(
																item.price /
																(conversionRates[user_currency]?.[
																	item.currency.toLowerCase()
																] || 1)
															).toFixed(2)}
															displayType={'text'}
															thousandSeparator={true}
															prefix={
																currencies[user_currency.toUpperCase()]
																	?.symbol || '$'
															}
														/>
													)}
												</>
											) : (
												<>
													{item.price !== discountedPrice &&
													discountedPrice < item.price ? (
														<>
															<NumericFormat
																value={item.price.toFixed(2)}
																displayType={'text'}
																thousandSeparator={true}
																prefix={
																	(user_currency &&
																		currencies[user_currency.toUpperCase()]
																			?.symbol) ||
																	'$'
																}
																className='text-muted text-decoration-line-through'
															/>
															&nbsp; {/* Adding non-breaking space here */}
															<NumericFormat
																value={discountedPrice.toFixed(2)}
																displayType={'text'}
																thousandSeparator={true}
																prefix={
																	(user_currency &&
																		currencies[user_currency.toUpperCase()]
																			?.symbol) ||
																	'$'
																}
																className='text-danger'
															/>
														</>
													) : (
														<NumericFormat
															value={item.price.toFixed(2)}
															displayType={'text'}
															thousandSeparator={true}
															prefix={
																(user_currency &&
																	currencies[user_currency.toUpperCase()]
																		?.symbol) ||
																'$'
															}
														/>
													)}
												</>
											)}
										</Col>
										<Col md={2}>
											<Form.Control
												as='select'
												value={item.qty}
												onChange={(e) =>
													dispatch(
														addToCart(item.product, Number(e.target.value))
													)
												}
											>
												{[...Array(Math.max(0, item.countInStock)).keys()].map(
													(x) => (
														<option key={x + 1} value={x + 1}>
															{x + 1}
														</option>
													)
												)}
											</Form.Control>
										</Col>
										<Col md={2}>
											<Button
												type='button'
												variant='light'
												onClick={() => removeFromCartHandler(item.product)}
											>
												<i className='fas fa-trash'></i>
											</Button>
										</Col>
									</Row>
								</ListGroup.Item>
							);
						})}
					</ListGroup>
				)}
			</Col>
			{cartItems.length !== 0 && (
				<Col md={4}>
					<Card>
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<h2>Subtotal ({subtotal}) Items</h2>
								{totalDiscount > 0 ? (
									<>
										<NumericFormat
											value={originalTotalPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[user_currency.toUpperCase()].symbol
													: '$'
											}
											className='text-muted text-decoration-line-through'
										/>
										&nbsp; {/* Adding non-breaking space here */}
										<NumericFormat
											value={totalPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[user_currency.toUpperCase()].symbol
													: '$'
											}
											className='text-danger'
										/>
										&nbsp; {/* Adding non-breaking space here */}
										<span className='text-success'>
											(~{discountPercentage.toFixed(0)}% off)
										</span>
									</>
								) : (
									<NumericFormat
										value={totalPrice}
										displayType={'text'}
										thousandSeparator={true}
										prefix={
											user_currency
												? currencies[user_currency.toUpperCase()].symbol
												: '$'
										}
									/>
								)}
							</ListGroup.Item>
							<ListGroup.Item>
								<Button
									type='button'
									className='btn-block'
									disabled={cartItems.length === 0}
									onClick={checkoutHandler}
								>
									Proceed To Checkout
								</Button>
							</ListGroup.Item>
						</ListGroup>
					</Card>
				</Col>
			)}
		</Row>
	);
};

export default CartScreen;
